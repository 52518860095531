import {
  AllCategoriesQueryVariables,
  DataAvailabilityQueryVariables,
  DateSeriesQueryVariables,
  DiseaseQueryVariables,
  FeatureFlagQueryVariables,
  FeatureFlagsQueryVariables,
  GeoStatsQueryVariables,
  LocationQueryVariables,
  LocationsForMapsQueryVariables,
  LocationStatQueryVariables,
  LocationStatsFromConfigWithDateSeriesQueryVariables,
  LocationStatsQueryVariables,
  LocationStatSubtypeStatsQueryVariables,
  LocationStatsWithDateSeriesQueryVariables,
  LocationVideosQueryVariables,
  MapQueryVariables,
  MapWithStatsQueryVariables,
  MhcGeographyEnum,
  MhcStatIdentifierWithAvailableGeosFragment,
  NotesQueryVariables,
  PageQueryVariables,
  PagesQueryVariables,
  PdfQueryVariables,
  StatIdentifierQueryVariables,
  StatIdentifiersQueryVariables,
  StatIdentifiersWithAvailableGeosQueryVariables,
  TimeSeriesQueryVariables,
  TopicQueryVariables,
  WeekSeriesQueryVariables
} from "../types";

import { embolden, log, logInfo } from "common/util/consoleHelpers";

import { legacyGraphqlSdk as graphqlSdk } from "./sdk";

const logDataLoading = (fn: string, opts: unknown) => {
  if (!process.env.APOLLO_CLIENT_LOADING_LOGGING_ENABLED) return;
  logInfo(`Requesting Data from client ${embolden(fn)}`);
  log({ opts });
};

// Location
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

/**
 * Gets location without geoJSON
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getLocation = async (opts: LocationQueryVariables) => {
  logDataLoading("getLocation", opts);
  const { location } = await graphqlSdk.location(opts);
  return location;
};

/**
 * Gets location without geoJSON and county data
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getLocationWithGeoJson = async (opts: LocationQueryVariables) => {
  logDataLoading("getLocationWithGeoJson", opts);
  const { location } = await graphqlSdk.locationWithGeoJson(opts);
  return location;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getPotentiallyAlteredLocation = async (opts: LocationQueryVariables) => {
  logDataLoading("getPotentiallyAlteredLocation", opts);
  const { location } = await graphqlSdk.alteredLocation(opts);
  return location;
};

/**
 * @param geographies
 * @param options
 * @param options.withGeoJson
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getAllLocationsFromGeographies = async (
  geographies: MhcGeographyEnum | MhcGeographyEnum[],
  options: { withGeoJson?: boolean } = { withGeoJson: true }
) => {
  logDataLoading("getAllLocationsFromGeographies", options);
  const { locations } = options.withGeoJson
    ? await graphqlSdk.allLocations({ geographies })
    : await graphqlSdk.allLocationsWithoutGeoJson({ geographies });
  return locations;
};

/**
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getTopLevelLocations = async () => {
  logDataLoading("getTopLevelLocations", {});
  const { locations } = await graphqlSdk.allLocations({
    geographies: [MhcGeographyEnum.ZipCode, MhcGeographyEnum.County, MhcGeographyEnum.State]
  });
  return locations;
};

/**
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcZipcodes = async () => {
  logDataLoading("getMhcZipcodes", {});
  const { locations } = await graphqlSdk.allLocations({ geographies: [MhcGeographyEnum.ZipCode] });
  return locations;
};

/**
 * @param opts
 * @param opts.withGeoJson
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcSchoolDistricts = async (
  { withGeoJson }: { withGeoJson?: boolean } = { withGeoJson: true }
) => {
  logDataLoading("getMhcSchoolDistricts", { withGeoJson });
  return await getAllLocationsFromGeographies(
    [MhcGeographyEnum.SchoolDistrict, MhcGeographyEnum.State],
    { withGeoJson }
  );
};

// Stat Identifier
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcStatIdentifier = async (opts: StatIdentifierQueryVariables) => {
  logDataLoading("getMhcStatIdentifier", opts);
  return (await graphqlSdk.statIdentifier(opts)).statIdentifier;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcStatIdentifiersWithAvailableGeos = async (
  opts: StatIdentifiersWithAvailableGeosQueryVariables
): Promise<MhcStatIdentifierWithAvailableGeosFragment[]> => {
  if (opts.ids.length === 0) return [];
  logDataLoading("getMhcStatIdentifierWithAvailableGeos", opts);
  return (await graphqlSdk.statIdentifiersWithAvailableGeos(opts)).statIdentifiers;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcStatIdentifiers = async (opts?: StatIdentifiersQueryVariables) => {
  logDataLoading("getMhcStatIdentifiers", opts);
  const { statIdentifiers } = await graphqlSdk.statIdentifiers(opts);
  return statIdentifiers;
};

// Location Stat
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcLocationStat = async (opts: LocationStatQueryVariables) => {
  logDataLoading("getMhcLocationStat", opts);
  return (await graphqlSdk.locationStat(opts)).location.stat;
};

// Location Stats From Config with Date Series
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const getMhcLocationStatsFromConfigWithDateSeries = async (
  opts: LocationStatsFromConfigWithDateSeriesQueryVariables
) => {
  logDataLoading("getMhcLocationStatsFromConfigWithDateSeries", opts);
  return (await graphqlSdk.locationStatsFromConfigWithDateSeries(opts)).locationStatsByConfig;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcLocationSubStats = async (opts: LocationStatSubtypeStatsQueryVariables) => {
  logDataLoading("getMhcLocationSubStats", opts);
  return (await graphqlSdk.locationStatSubtypeStats(opts)).locationStatSubtypeStats;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcLocationStats = async (opts: LocationStatsQueryVariables) => {
  logDataLoading("getMhcLocationStats", opts);
  const { locationStats = [] } = await graphqlSdk.locationStats(opts);
  return locationStats;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcGeoStat = async (opts: GeoStatsQueryVariables) => {
  logDataLoading("getMhcGeoStat", opts);
  return (await graphqlSdk.geoStats(opts)).statIdentifier;
};

/**
 * Introduced to prevent breaking changes
 * We should update getMhcLocationStats to return MhcLocationStat fragment instead
 * of MhcLocationStatWithDataSeries to reduce data loaded
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcLocationStatsWithoutDateSeries = async (opts: LocationStatsQueryVariables) => {
  logDataLoading("getMhcLocationStats", opts);
  const { locationStats = [] } = await graphqlSdk.locationStatsWithoutDateSeries(opts);
  return locationStats;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcLocationStatsWithDateSeries = async (
  opts: LocationStatsWithDateSeriesQueryVariables
) => {
  logDataLoading("getMhcLocationStatsWithDateSeries", opts);
  return (await graphqlSdk.locationStatsWithDateSeries(opts)).locationStats;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcTimeSeries = async (opts: TimeSeriesQueryVariables) => {
  logDataLoading("getMhcTimeSeries", opts);
  return (await graphqlSdk.timeSeries(opts)).location.stat;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcDateSeries = async (opts: DateSeriesQueryVariables) => {
  logDataLoading("getMhcDateSeries", opts);
  return (await graphqlSdk.dateSeries(opts)).location.stat;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcLocationTimeSeries = async (opts: TimeSeriesQueryVariables) => {
  logDataLoading("getMhcLocationTimeSeries", opts);
  return (await graphqlSdk.timeSeries(opts)).location;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcLocationDateSeries = async (opts: DateSeriesQueryVariables) => {
  logDataLoading("getMhcLocationDateSeries", opts);
  return (await graphqlSdk.dateSeries(opts)).location;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcWeekSeries = async (opts: WeekSeriesQueryVariables) => {
  logDataLoading("getMhcWeekSeries", opts);
  return (await graphqlSdk.weekSeries(opts)).location.stat;
};

// Mapping
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcMapDataWithStats = async (opts: LocationsForMapsQueryVariables) => {
  logDataLoading("getMhcMapDataWithStats", opts);
  return (await graphqlSdk.locationsForMaps(opts)).locations;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcMapWithStats = async (opts: MapWithStatsQueryVariables) => {
  logDataLoading("getMhcMapWithStats", opts);
  return (await graphqlSdk.mapWithStats(opts)).map;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcMap = async (opts: MapQueryVariables) => {
  logDataLoading("getMhcMap", opts);
  return (await graphqlSdk.map(opts)).map;
};

// Media
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcLocationVideos = async (opts: LocationVideosQueryVariables) => {
  logDataLoading("getMhcLocationVideos", opts);
  const {
    statIdentifier: { locationVideos = [], ..._statIdentifier }
  } = await graphqlSdk.locationVideos(opts);
  return {
    statIdentifier: _statIdentifier,
    videos: locationVideos
  };
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getPdf = async (opts: PdfQueryVariables) => {
  logDataLoading("getPdf", opts);
  return (await graphqlSdk.pdf(opts)).pdf;
};

// Topics
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcPages = async (opts: PagesQueryVariables) => {
  logDataLoading("getMhcPages", opts);
  const { topics } = await graphqlSdk.pages(opts);
  return topics;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcPage = async (opts: PageQueryVariables) => {
  logDataLoading("getMhcPage", opts);
  const { topic } = await graphqlSdk.page(opts);
  return topic;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcCategories = async (opts: AllCategoriesQueryVariables) => {
  logDataLoading("getMhcCategories", opts);
  const { categories } = await graphqlSdk.allCategories(opts);
  return categories;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcTopic = async (opts: TopicQueryVariables) => {
  logDataLoading("getMhcTopic", opts);
  const { topic } = await graphqlSdk.topic(opts);
  return topic;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getNotes = async (opts: NotesQueryVariables) => {
  logDataLoading("getNotes", opts);
  return (await graphqlSdk.notes(opts)).notes;
};

// Feature Flags
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcFeatureFlag = async (opts: FeatureFlagQueryVariables) => {
  logDataLoading("getMhcFeatureFlag", opts);
  const { featureFlag } = await graphqlSdk.featureFlag(opts);
  return featureFlag;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getMhcFeatureFlags = async (opts: FeatureFlagsQueryVariables) => {
  logDataLoading("getMhcFeatureFlags", opts);
  const { featureFlags } = await graphqlSdk.featureFlags(opts);
  return featureFlags;
};

// Disease
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getDisease = async (opts: DiseaseQueryVariables) => {
  logDataLoading("getDisease", opts);
  const { disease } = await graphqlSdk.disease(opts);
  return disease;
};

/**
 * @param opts
 * @returns -
 *
 * @deprecated The method should not be used
 */
export const getDataAvailability = async (opts: DataAvailabilityQueryVariables) => {
  logDataLoading("getDataAvailability", opts);
  const { dataAvailability } = await graphqlSdk.dataAvailability(opts);
  return dataAvailability;
};
