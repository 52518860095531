import { ImageProps } from "next/image";
import Image from "next/legacy/image";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import logo from "public/images/mhc-logo.svg";
import logoMobile from "public/images/mhc-logo-mobile.svg";

interface Props {
  width?: number;
  height?: number;
  style?: ImageProps["style"];
}

export const MhcLogo: React.FC<Props> = ({ width = 350, height = 35, style }) => {
  const { breakpoints } = useTheme();
  let _logo = logo;
  let _width = width;
  if (useMediaQuery(breakpoints.down("md"))) {
    _logo = logoMobile;
    _width = 250;
  }
  if (useMediaQuery(breakpoints.down("sm"))) {
    _width = 200;
  }
  return (
    <Image
      alt="My Healthy Community: Delaware Environmental Public Health Tracking Network"
      width={_width}
      height={height}
      src={_logo}
      style={style}
    />
  );
};
